<template>
  <div>
    <ModalLicencesSupp
      v-if="isGE || isNumerisak"
      ref="modalLicencesSupp"
      :lignes="data ? data.lignes : []"
      @submit="licencesSuppSubmit($event)"
    />
    <Modal
      id="modal_devis_commande"
      :class="{ empty, }"
      @change="!$event && hide()"
      @before-open="checkGratuite"
      @hidden="hide()"
    >
      <template #title>
        {{
          destinationType === "devis"
            ? $t("devis.passage-en-devis")
            : $t("commande.passage-en-commande")
        }}
      </template>
      <template
        v-if="!empty"
        #subtitle
      >
        {{
          destinationType === "devis"
            ? $t("devis.vous-etes-sur-le-point-etablir-devis")
            : $t("commande.vous-etes-sur-le-point-passer-cmd")
        }}
      </template>

      <template #content>
        <section v-if="empty">
          <p>
            {{
              empty
                ? $t("commande.vous-etes-sur-le-point-passer-cmd")
                : $t(
                  "general.vous-trouverez-ci-apres-infos-en-relation-a-verifier-infos-obligatoire-asterisque"
                )
            }}
          </p>
        </section>
        <div
          v-if="erreur"
          class="error"
        >
          <p>{{ $t("devis.une-erreur-est-survenue-actualiser") }}</p>
          <p>{{ $t("general.si-probleme-persiste-contacter-lde-mail") }}</p>
        </div>
        <template v-else-if="!isLoading">
          <!-- Sélection d'organisme -->
          <section v-if="!skipBascule">
            <h3 class="title-separator">
              <span>{{ $t("general.choix-ets") }}</span>
            </h3>
            <div class="bloc">
              <p>
                {{
                  destinationType === "devis"
                    ? $t("devis.creation-devis-choix-ets")
                    : $t("commande.commande-sera-passee-sur-compte-ci-contre")
                }}
              </p>
              <SelectOrganismeInput
                v-model="selectedOrg"
                :disabled="sourceType !== 'liste'"
              >
                <template #after-option-item="{ option: org, }">
                  <Tag
                    v-if="org.type"
                    :tag-name="org.type"
                  />
                </template>
              </SelectOrganismeInput>
            </div>
          </section>
          <!-- Fonds propres -->
          <section
            v-if="organismeActuel.fonds_propres"
            id="fonds_propres"
          >
            <h3 class="title-separator">
              <span>{{ $t("commande.commande-sur-fonds-propres") }}</span>
            </h3>
            <p v-html="texteFondsPropres" />
          </section>

          <!-- Nommer le devis ou la commande -->
          <section v-if="sourceType !== 'devis'">
            <h3 class="title-separator">
              <span>
                {{
                  destinationType === "devis"
                    ? $t("devis.intitule-devis")
                    : $t("commande.intitule-commande")
                }}
              </span>
            </h3>
            <div class="bloc">
              <p>
                <template v-if="destinationType === 'devis'">
                  {{
                    $t(
                      "devis.vous-etes-sur-le-point-creer-devis-possibilite-nommer"
                    )
                  }}
                </template>
                <template v-else>
                  {{
                    sourceType === "etiquette"
                      ? $t("commande.donner-nom-commande")
                      : $t(
                        "commande.vous-etes-sur-le-point-creer-commande-possibilite-nommer"
                      )
                  }}
                </template>
              </p>
              <InputClassic
                v-model="nameInput"
                :label="`${
                  destinationType === 'devis'
                    ? $t('devis.nom-du-devis')
                    : $t('devis.nom-de-commande')
                }
                  (${$t('general.optionnel')})`"
                :placeholder="panier ? tempNamePanier : data.libelle"
              />
            </div>
          </section>

          <!-- CGV -->
          <section v-if="allCgv.length">
            <h3 class="title-separator">
              <span>{{ $t("conditions-generales-de-vente") }}</span>
            </h3>
            <p>
              <strong v-html="$t('general.attention')" />
              <br />
              {{ $t("liste.cgv-detail") }}
            </p>
            <div id="all_cgv">
              <ButtonClassic
                v-for="cgv in allCgv"
                :key="cgv[0]"
                balise-type="a"
                :label="cgv[0]"
                color="primary"
                icon="left"
                :ext-link="cgv[1]"
              >
                <template #left-icon>
                  <UilExternalLinkAlt />
                </template>
              </ButtonClassic>
            </div>
            <ButtonCheckbox
              v-model="checkedCGV"
              :label="$t('liste.cocher-accepter-cgv')"
              name="cgv"
              left-label
            />
            <UilAsterisk class="required" />
          </section>

          <!-- Chorus -->
          <section
            v-if="
              organismeActuel.infos.chorus_configuration.chorus === 1 &&
                destinationType === 'commande'
            "
            id="bloc_chorus"
          >
            <h3 class="title-separator">
              <span>{{ $t("chorus.informations-facturation") }}</span>
            </h3>
            <div class="bloc">
              <InputClassic
                v-model="siretInput"
                :label="$t('chorus.n-de-siret-du-payeur')"
                placeholder="12345678900000"
                status="required"
                maxlength="14"
                :disabled="!isSiretEmpty"
              />
              <InputClassic
                v-model="engagementInput"
                :label="$t('chorus.n-d-engagement')"
                :status="
                  isEngagementRequired && sourceType !== 'etiquette'
                    ? 'required'
                    : 'none'
                "
                :required="isEngagementRequired"
                :placeholder="$t('devis-commande.exemple-num-engagement')"
              />
              <SelectClassic
                v-if="showServiceSelect"
                v-model="selectedService"
                :options="optionsServices"
                :label="$t('chorus.service-concerne')"
                :status="isServiceRequired ? 'required' : 'none'"
                :required="isServiceRequired"
                :placeholder="$t('chorus.selectionner-service-etablissement')"
                options-top
              />
            </div>
          </section>
        </template>
        <InfiniteLoader v-else />
      </template>

      <template #footer>
        <template v-if="!erreur">
          <button
            class="button text-medium underline"
            :disabled="isLoading"
            @click="hide()"
          >
            {{ $t("action.annuler") }}
          </button>
          <ButtonClassic
            variant="solid"
            :label="
              actionIsSubmit
                ? $t('action.valider')
                : $t('action.basculer-ma-liste')
            "
            :color="actionIsSubmit ? 'primary' : 'secondary'"
            icon="right"
            :disabled="disableButton"
            @click="
              actionIsSubmit ? submit() : $emit('bascule-liste', selectedOrg)
            "
          >
            <template #right-icon>
              <UilCheckCircle />
            </template>
          </ButtonClassic>
        </template>
        <template v-else>
          <p />
          <ButtonClassic
            :label="$t('action.actualiser-la-page')"
            color="secondary"
            variant="solid"
            @click="reload"
          />
        </template>
      </template>
    </Modal>
  </div>
</template>

<script>
import {
  Modal,
  ButtonClassic,
  Tag,
  InputClassic,
  SelectClassic,
  ButtonCheckbox,
  InfiniteLoader,
} from "@lde/core_lde_vue";

import SelectOrganismeInput from "@/components/forms/SelectOrganismeInput.vue";

import {
  UilAsterisk,
  UilCheckCircle,
  UilExternalLinkAlt,
} from "@iconscout/vue-unicons";
import ModalLicencesSupp from "@/components/modals/ModalLicencesSupp.vue";
import { mapGetters } from "vuex";

/**
 * Modale comprenant l'ensemble des éléments nécessaires pour le passage en devis et en commande.
 */
export default {
  name: "ModalDevisCommande",
  components: {
    Modal,
    ButtonClassic,
    Tag,
    InputClassic,
    SelectClassic,
    ButtonCheckbox,
    SelectOrganismeInput,
    InfiniteLoader,
    UilAsterisk,
    UilCheckCircle,
    UilExternalLinkAlt,
    ModalLicencesSupp,
  },
  props: {
    /**
     * Définit le type de l'élément de base.
     */
    sourceType: {
      type: String,
      validator: (value) => ["liste", "devis", "etiquette"].includes(value),
      required: true,
    },
    /**
     * Définit le type de l'élément de destination.
     */
    destinationType: {
      type: String,
      validator: (value) => ["devis", "commande"].includes(value),
      required: true,
    },
    /**
     * Informations relatives à la liste.
     */
    data: {
      type: Object,
      default: () => {},
    },
    /**
     * CGV à accepter.
     */
    allCgv: {
      type: Array,
      default: () => [],
    },
    /**
     * Numéro d'engagement de la commande.
     */
    engagement: {
      type: String,
      default: "",
    },
    /**
     * Informations relatives à l'organisme qui n'est pas celui actuel (maitre compta)
     */
    organisme: {
      type: Object,
      default: () => null,
    },
    /**
     * Définit si on doit passer par la bascule de liste.
     */
    skipBascule: {
      type: Boolean,
      default: false,
    },
    /**
     * True s'il s'agit du passage d'un panier à un devis / une commande
     */
    panier: {
      type: Boolean,
      default: false,
    },
    /**
     * Définit si on doit afficher une erreur.
     */
    erreur: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    /**
     * Déclenché lorsqu'on clique sur le bouton de bascule de liste.
     */
    "bascule-liste",
    /**
     * Déclenché lorsqu'on clique sur le bouton de soumission de la modale.
     */
    "submit",
  ],
  data() {
    return {
      isLoading: false,
      selectedOrg: null,
      nameInput: null,
      checkedCGV: false,
      engagementInput: "",
      siretInput: "",
      selectedService: {},
      optionsServices: [],
      showServiceSelect: false,
      isEngagementRequired: false,
      isServiceRequired: false,
      licencesSupp: false,
    };
  },
  computed: {
    ...mapGetters([
      "urlHotline",
      "organismeActuel",
      "isGE",
      "isNumerisak",
      "user",
    ]),
    texteFondsPropres() {
      const nousContacterLien = `
        <a
          href="${this.urlHotline}"
          target="_blank"
          rel="noreferrer noopener"
          class="underline"
        >
          ${this.$t("general.nous-contacter")}
        </a>.
      `;

      const tradKey = this.sourceType === "liste"
        ? "verif-compte.liste-etablie-sur-fonds-propres-contactez-nous"
        : "verif-compte.devis-etabli-sur-fonds-propres-contactez-nous";

      return this.$t(tradKey, { nousContacterLien });
    },
    isSiretEmpty() {
      return !this.organismeActuel.infos.chorus_configuration.siret;
    },
    actionIsSubmit() {
      const isBascule = this.sourceType === "liste"
        && (!this.selectedOrg || this.selectedOrg?.id !== this.organismeActuel.id);

      return !isBascule;
    },
    disableButton() {
      const conditionsChorus = this.organismeActuel.infos.chorus_configuration.chorus === 1
        && this.destinationType === "commande"
        && (this.siretInput.trim().length !== 14
          || (this.isEngagementRequired
            && !this.engagementInput
            && this.sourceType !== "etiquette")
          || (this.isServiceRequired
            && !Object.keys(this.selectedService).length));

      const conditionsCgv = this.allCgv.length && !this.checkedCGV;

      return (
        this.isLoading
        || !this.selectedOrg
        || (this.selectedOrg?.id === this.organismeActuel.id
          && (conditionsCgv || conditionsChorus))
      );
    },
    empty() {
      return (
        (this.skipBascule
          || this.selectedOrg?.id !== this.organismeActuel.id)
        && !this.organismeActuel.fonds_propres
        && !this.allCgv.length
      );
    },
    tempNamePanier() {
      return `${this.$tc("commande.commande")} ${this.user.contact.nom}`;
    },
  },
  mounted() {
    this.defineDefaultOrganisme();
    this.defineChorusInfos();
  },
  methods: {
    checkGratuite() {
      this.defineChorusInfos();
      if (this.sourceType === "liste" && (this.isGE || this.isNumerisak)) {
        this.isLoading = true;
        return this.$refs.modalLicencesSupp.open().then(() => {
          this.isLoading = false;
        });
      }
      return true;
    },
    licencesSuppSubmit($event) {
      this.licencesSupp = $event;
      this.$modal.hide("modal_licences_supp");
    },
    /**
     * Ferme la modale et remet les valeurs par défaut.
     */
    hide() {
      this.$modal.hide("modal_devis_commande");
      this.nameInput = null;
      this.checkedCGV = false;
      this.isLoading = false;
    },
    /**
     * Définit l'organisme actuel comme étant celui par défaut pour le select d'organisme.
     */
    defineDefaultOrganisme() {
      const org = this.organisme || this.organismeActuel;

      this.selectedOrg = {
        label: org.nom_complet,
        value: org.nom_complet,
        id: org.id,
        type: org.type,
      };
    },
    /**
     * Définit les informations liées à Chorus (numéro d'engagement et services).
     */
    defineChorusInfos() {
      const {
        siret,
        need_engagement: needEngagement,
        need_service: needService,
        need_service_or_engagement: needServiceOrEngagement,
        services,
        codeservice_lastused: codeServiceLastUsed,
      } = this.organismeActuel.infos.chorus_configuration;

      this.siretInput = siret || "";

      if (needEngagement) {
        this.isEngagementRequired = true;
      }
      if (needService) {
        this.isServiceRequired = true;
      }
      if (needService || needServiceOrEngagement) {
        this.showServiceSelect = true;
      }

      this.$nextTick(() => {
        this.engagementInput = this.engagement;
      });

      this.optionsServices = services?.map((service) => ({
        label: service.Nom,
        value: service.Code,
        id: service.Code,
      }));

      if (this.isServiceRequired) {
        if (services.length === 1) {
          // Si on n'a qu'un service, on le met par défaut
          this.selectedService = {
            label: services[0].Nom,
            value: services[0].Nom,
            id: services[0].Code,
          };
        } else if (codeServiceLastUsed !== "") {
          // Sinon, si on a un service précédement utilisé, on le met par défaut
          const lastUsed = services.find(
            (service) => service.Code === codeServiceLastUsed,
          );
          if (lastUsed) {
            this.selectedService = {
              label: lastUsed.Nom,
              value: lastUsed.Nom,
              id: lastUsed.Code,
            };
          }
        }
      }
    },
    /**
     * Met à jour le SIRET.
     * @returns {Promise}
     */
    updateSiret() {
      if (this.siretInput.trim().length === 14) {
        this.isLoading = true;

        return this.$store
          .dispatch("updateSiret", {
            idOrganisme: this.organismeActuel.id,
            siret: this.siretInput,
          })
          .then(() => {
            this.$toast.success({
              title: this.$t("chorus.numero-de-siret-mis-a-jour"),
            });
          })
          .catch((error) => {
            this.$toast.error({
              title:
                error.response.data.message
                || this.$t("info.une-erreur-est-survenue"),
            });
          });
      }

      return this.$toast.error({
        title: this.$t("chorus.numero-de-siret-doit-etre-valide"),
      });
    },
    /**
     * Valide le passage de la liste/du devis en devis/commande (change le SIRET le cas échéant).
     * @returns {Promise}
     */
    submit() {
      this.isLoading = true;

      const {
        chorus,
        need_service_or_engagement: needServiceOrEngagement,
      } = this.organismeActuel.infos.chorus_configuration;

      let promise = Promise.resolve();

      if (
        needServiceOrEngagement
        && !this.engagementInput
        && !this.selectedService
      ) {
        return this.$toast.error({
          title: this.$t(
            "chorus.veuillez-renseigner-au-moins-num-engagement-ou-service-concerne",
          ),
        });
      }

      if (this.isSiretEmpty && chorus === 1) {
        promise = this.updateSiret();
      }

      let libelle = this.nameInput;

      if (!libelle) {
        libelle = this.panier ? this.tempNamePanier : this.data?.libelle;
      }

      return promise.then(() => {
        const params = {
          destinationType: this.destinationType,
          libelle,
          num_engagement: this.engagementInput,
          licencesSupp: this.licencesSupp,
        };

        if (this.showServiceSelect) {
          params.code_service = this.selectedService.id;
        }

        this.$emit("submit", params);
      });
    },
    /**
     * Actualise la page.
     */
    reload() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/modals/modal_devis_commande.scss";
</style>
